@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/mixins";

.map-style {
  $base: &;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  &:hover #style-title {
    color: accent.$accent_06;
  }

  &__image-wrapper:hover:not(&__image-wrapper--active):after {
    width: 86px;
    height: 50px;
    position: absolute;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 4px;
    background-color: rgba(0, 0, 0, 0.2);
    border: 1px solid accent.$accent_06;
    top: 0;
    left: 0;
    content: "";
  }

  &__image-wrapper--hover--active {
    cursor: default;
  }

  &__image {
    width: 86px;
    height: 50px;
    border: 1px solid neutral.$neutral_04;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 4px;
    margin-bottom: spacing.$spacing-xxs;

    &:active,
    &--active {
      border: 2px solid accent.$accent_05;
      outline: 2px solid accent.$accent_01;
      outline-offset: -4px;
    }
  }

  &__image--hover--active {
    cursor: default;
  }

  &__title--hover {
    &--active {
      cursor: default;
    }
  }

  &__message {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;

    .tooltip__trigger {
      margin-left: spacing.$spacing-xs;
      @include mixins.svg-fill(neutral.$neutral_04);
    }
  }
}
