@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/text";

.map-option-shortcuts {
  margin-top: spacing.$spacing-s;
  font-family: text.$gilroysemibold;
}

.shortcut-button-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

