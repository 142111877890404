@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/effects";
@use "sass/ui-library/utilities/mixins";
@use "sass/utilities/variables/dimentions";

.panels-container {
  position: absolute;
  left: dimentions.$base-left-space;
  top: dimentions.$base-top-space;
  z-index: 1;
  @media (max-width: dimentions.$max-width-mobile) {
    width: 82%;
  }
}

.panel-template {
  &--support-small {
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 3;

    .panel {
      max-height: dimentions.$base-max-height;
    }
  }

  &--top,
  &--overlay {
    -webkit-box-shadow: effects.$drop-shadow-down-s-dark;
            box-shadow: effects.$drop-shadow-down-s-dark;
  }

  &--bottom,
  &--overlay {
    position: absolute;
    top: 0;
  }

  &--bottom {
    z-index: -1;
    .slide-in-down-out-up {
      $close-height: dimentions.$header-height;

      &-enter {
        height: $close-height;
      }

      &-exit-active {
        height: $close-height !important;
      }
    }
  }

  &--overlay {
    z-index: 3;
    right: 0;
    width: unset;
  }

  &--top,
  &--bottom,
  &--overlay {
    -webkit-box-shadow: effects.$drop-shadow-down-m-light;
            box-shadow: effects.$drop-shadow-down-m-light;
    width: 100%;
    border-radius: dimentions.$base-border-radius;
    background-color: neutral.$white_100;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
    max-height: dimentions.$base-max-height;

    $aw: ".animation-wrapper";

    #{$aw} {
      overflow: hidden;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: vertical;
      -webkit-box-direction: normal;
          -ms-flex-direction: column;
              flex-direction: column;
      border-radius: dimentions.$base-bottom-border-radius;
      z-index: 0;

      &__content {
        overflow: hidden;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;

        & > * {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: vertical;
          -webkit-box-direction: normal;
              -ms-flex-direction: column;
                  flex-direction: column;
          overflow: auto;
        }
      }

      /* .animation-wrapper--search-routes is the top panel which slides up and down (vertical)*/
      &--search-routes {
        /* to make sure that only the first wrapper content is effected*/
        & > #{$aw}__content {
          /* using grid for the overlapping the panels during the horizontal animation while at the same time*/
          /* the content's height is still available (with position absolute the panel would*/
          /* not be aware of the content's height)*/
          display: grid;
          grid-template-columns: 1fr;

          /* .animation-wrapper--search-route is for the route which moves left to right (horizontal)*/
          & #{$aw}--search-route {
            grid-row-start: 1;
            grid-column-start: 1;
            grid-row-end: 1;
            grid-column-end: 1;
            /*setting a max height excluding the header to trigger the scroll bar in chrome*/
            max-height: dimentions.$base-max-height-wo-header;
          }
        }
      }

      &--planner {
        /* to make sure that only the first wrapper content is effected*/
        & > #{$aw}__content > div {
          margin-top: dimentions.$header-height;
        }
      }
    }
  }

  &--planner {
    background-color: accent.$accent_08;
  }

}
