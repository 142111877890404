@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/mixins";

.search-results {
  &--search {
    overflow: hidden !important;
    .list-group-item-wrapper:hover {
      background: neutral.$neutral_01;
    }
  }

  .list-group-item-wrapper {
    border-radius: 8px;
  }

  &--default,
  &--with-results {
    padding: 8px 4px 0 4px;
  }

  &--planner {
    max-height: 42vh;
    min-height: 0;
    padding-bottom: spacing.$spacing-xs;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;

    .list-group-item,
    .lists-item,
    .list-group-item-wrapper {
      background-color: accent.$accent_09;
    }

    .list-group-item-wrapper:hover {
      background: accent.$accent_08;
    }

    .list-group-item-wrapper,
    .current-location {
      .list-group-item {
        @include mixins.svg-fill(accent.$accent_04);
      }
    }

    .list-group-item.heading {
      color: neutral.$white_100;
    }

    .list-two-line-text__bottom-left,
    .list-two-line-text__bottom-right {
      color: accent.$accent_04;
    }
  }

  .heading {
    padding-left: spacing.$spacing-xs !important;
  }

  .lists-item__wrapper {
    padding: 0 7px 0 spacing.$spacing-xxs;
    .list-two-line-text__bottom-left {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      white-space: nowrap;
    }
  }

  .list-group-item {
    padding: spacing.$spacing-xs 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    svg {
      margin-right: spacing.$spacing-s;
      -ms-flex-negative: 0;
          flex-shrink: 0;
    }
  }

  mark {
    background: transparent;
    padding: 0;
  }

  .list-two-line-text__title {
    mark {
      @include mixins.typography-properties("accent-text");
    }
  }

  .list-two-line-text__text-bottom {
    mark {
      @include mixins.typography-properties("accent-text-s");
    }
  }

  .list-two-line-text__bottom-right {
    -ms-flex-negative: 0;
        flex-shrink: 0;
  }

  .lists-item {
    background-color: transparent;
    min-height: auto;
    padding: 0;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    width: 80%;
    &__text {
      max-width: calc(100% - 32px);
    }
  }

  .list-group-item-action:hover,
  .list-group-item-action:focus {
    background-color: transparent;
  }

  .text-with-icon {
    font-size: 16px;
    line-height: 20px;
    margin: 0;

    &__icon {
      margin-right: spacing.$spacing-xs;

      svg {
        path {
          fill: #737f89;
        }
      }
    }
  }
}
