@use "sass/ui-library/utilities/mixins";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/effects";
@use "sass/ui-library/utilities/variables/transitions";
@use "sass/utilities/variables/dimentions";

/*styled as a mapbox map control*/

.maplibregl-ctrl-group:not(:empty) {
  border-radius: 20px;
  background: neutral.$white_100;
  -webkit-box-shadow: effects.$drop-shadow-down-s-dark;
          box-shadow: effects.$drop-shadow-down-s-dark;
}

.maplibregl-ctrl-group button {
  @include mixins.size(32px);
}

$right: 10px;

.map-options-toggle {
  $base: &;

  position: absolute;
  bottom: 201px;
  right: $right;
  @include mixins.transition(transform, transitions.$transition-duration-xl);

  $shadow-normal: effects.$drop-shadow-down-s-dark;
  $shadow-emphasis: 0 0 4px 2px accent.$accent_05;

  &--on {
    -webkit-transform: translateX(-(dimentions.$map-options-width));
            transform: translateX(-(dimentions.$map-options-width));
  }

  &__toggle {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    @include mixins.size(32px);
    padding: 0;
    outline: none;
    border: 0;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
    border-radius: 20px;
    background: neutral.$white_100;
    -webkit-box-shadow: $shadow-normal;
            box-shadow: $shadow-normal;

    &:not(:disabled):hover {
      background-color: #f2f2f2;
    }
  }

  @-webkit-keyframes mimic-click {
    0% {
      background: accent.$accent_03;
      opacity: 1;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    80% {
      opacity: 1;
    }
    100% {
      background: accent.$accent_03;
      opacity: 0;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  @keyframes mimic-click {
    0% {
      background: accent.$accent_03;
      opacity: 1;
      -webkit-transform: scale(0);
              transform: scale(0);
    }
    70% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    80% {
      opacity: 1;
    }
    100% {
      background: accent.$accent_03;
      opacity: 0;
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }

  &--emphasis {
    #{$base}__toggle {
      &:before {
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border-radius: 50%;
        -webkit-animation: mimic-click 500ms ease-out
          transitions.$transition-duration-xl;
                animation: mimic-click 500ms ease-out
          transitions.$transition-duration-xl;
      }
      svg {
        z-index: 1;
      }
    }
  }

  &:after {
    position: absolute;
    @include mixins.size(8px);
    background-color: accent.$accent_05;
    border-radius: 50%;
    top: 0;
    right: 0;
    content: "";
    opacity: 0;
    @include mixins.transition(opacity);
  }

  &:focus {
    outline: none;
    -webkit-box-shadow: effects.$drop-shadow-down-s-dark;
            box-shadow: effects.$drop-shadow-down-s-dark;
  }

  svg {
    path {
      @include mixins.transition(fill);
    }
  }

  &--on:not(:hover) {
    @include mixins.svg-fill(accent.$accent_05);
  }

  &--notification {
    &:after {
      opacity: 1;
    }
  }
}
