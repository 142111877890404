@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/spacing";

.cookie-panel {
  position: fixed;
  bottom: spacing.$spacing-xxl;
  width: 400px;
  z-index: 1;
  border-radius: 8px;
  padding: spacing.$spacing-l;
  background: neutral.$white_100;

  &__btn-wrapper {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin: spacing.$spacing-m 0 spacing.$spacing-s 0;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
  }

  &__options-container {
    margin-top: spacing.$spacing-s;
  }

  .lists-item {
    padding-left: 0;
    padding-right: 0;
  }
}

@media only screen and (max-width: 450px) {
  .cookie-panel {
    width: calc(100% - 52px);
  }
}
