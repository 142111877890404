@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/mixins";

.position-bullet {
  width: spacing.$spacing-m;
  text-align: center;

  @include mixins.svg-fill(accent.$accent_04);

  &--numbered {
    color: accent.$accent_04;
    font-family: fonts.$gilroybold;
    font-size: 12px;
    line-height: 16px;
    letter-spacing: 0.02em;
  }
}
