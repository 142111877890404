@use "sass/ui-library/utilities/variables/color/status";

.ev-connector {
  &.lists-item {
    margin-bottom: 0;
  }

  &--available {
    .lists-item__trailing-text {
      color: status.$positive_03;
    }
  }

  &--unavailable {
    .lists-item__trailing-text {
      color: status.$destructive_04;
    }
  }
}
