@use "sass/ui-library/utilities/variables/spacing";

.page-404 {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  &__image {
    margin-top: 41px;
  }

  &__title {
    width: 440px;
    text-align: center;
  }

  &__body {
    margin-top: spacing.$spacing-m;
    width: 440px;
    text-align: center;
  }

  &__button {
    margin-top: spacing.$spacing-m;
  }
}
