@use "sass/ui-library/utilities/variables/spacing";
@use "sass/utilities/variables/dimentions";

.search-this-area {
  position: fixed;
  left: 50%;
  -webkit-transform: translate(-50%, 0);
          transform: translate(-50%, 0);
  z-index: 10;
  top: 10px;
}

$left-space: dimentions.$base-width + dimentions.$base-left-space +
  spacing.$spacing-xxs;
$width-estimate: 160px;

@media (max-width: $left-space * 2 + $width-estimate) {
  .search-this-area {
    position: fixed;
    left: $left-space;
    -webkit-transform: none;
            transform: none;
  }
}
