@use "sass/ui-library/utilities/variables/spacing";

$base-top-space: spacing.$spacing-s;
$base-bottom-space: spacing.$spacing-s;
$base-left-space: spacing.$spacing-s;

$feedback-button-height: 40px;

$header-height: 56px;

$vertical-negative-space: $base-top-space + $base-bottom-space +
  $feedback-button-height;

$base-max-height: calc(100vh - #{$vertical-negative-space});
$base-max-height-wo-header: calc(
  100vh - #{$vertical-negative-space} - #{$header-height}
);
$base-width: 352px;
$max-width-mobile: 640px;
$base-border-radius: spacing.$spacing-xs;
$base-bottom-border-radius: 0 0 $base-border-radius $base-border-radius;

$map-options-width: 220px;

@mixin heightCalc($attr: height, $exclude: "0px", $inclHeader: false) {
  @if $inclHeader {
    #{$attr}: calc(
      100vh - #{$vertical-negative-space} - #{$header-height} - #{$exclude}
    );
  } @else {
    #{$attr}: calc(100vh - #{$vertical-negative-space} - #{$exclude});
  }
}
