@use "sass/ui-library/utilities/mixins";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/text/fonts";
@use "sass/ui-library/utilities/variables/transitions";
@use "sass/utilities/variables/dimentions";

.header {
  $base: &;
  $planner-icon-active-position: 30px;
  $planner-icon-animation-duration: 0.35s;
  $planner-icon-active-color: accent.$accent_05;
  $planner-icon-active-color-hover: accent.$accent_06;
  $planner-icon-inactive-color: neutral.$neutral_06;
  $planner-icon-inactive-color-hover: neutral.$neutral_07;

  display: -webkit-box;

  display: -ms-flexbox;

  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  border-radius: dimentions.$base-border-radius;
  @media (min-width: dimentions.$max-width-mobile) {
    width: dimentions.$base-width;
  }
  z-index: 3;

  &__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 56px;
    padding: spacing.$spacing-xs spacing.$spacing-m;
    background-color: neutral.$white_100;
    border-bottom: 1px white solid;
    border-radius: 8px;
  }

  $menu-icon-size: 32px;
  &__left-action,
  &__right-action {
    border-radius: 50%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
  }

  &__left-action {
    @include mixins.size($menu-icon-size);
    @include mixins.button-as-container();
    @include mixins.button-color-interaction();
  }

  &__middle-area {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    padding: 0 spacing.$spacing-s;
  }

  &__title {
    font-size: 16px;
    line-height: 20px;
    font-family: fonts.$gilroysemibold;
  }

  &__right-action {
    position: relative;
    @include mixins.size(24px);
    z-index: 1;
  }

  &__toast {
    $background-color: neutral.$neutral_06;

    position: absolute;
    background-color: $background-color !important;
    color: neutral.$white_100;
    left: 353px;
    white-space: nowrap;

    &:before {
      $border-size: 4px;
      position: absolute;
      content: "";
      right: 100%;
      top: 50%;
      height: 0;
      width: 0;
      pointer-events: none;
      border: $border-size solid transparent;
      border-right-color: $background-color;
      margin-top: $border-size * -1;
    }

    &.toast {
      pointer-events: none;
      width: auto;
    }

    .toast-body {
      padding: spacing.$spacing-xxs spacing.$spacing-s;
    }
  }

  &--planner,
  &--planner-settings {
    #{$base}__planner-toggle {
      position: absolute;
      top: $planner-icon-active-position;
      cursor: pointer;
      @include mixins.transition(top);
      @include mixins.svg-fill(neutral.$white_100);

      &:before {
        background-color: $planner-icon-active-color;
      }

      &:hover {
        &:before {
          background-color: $planner-icon-active-color-hover;
        }
      }
    }
  }

  &__planner-toggle {
    &:before {
      content: "";
      position: absolute;
      @include mixins.size(36px);
      border-radius: 50%;
      top: -6px;
      right: -6px;
      z-index: -1;
      @include mixins.transition(all, transitions.$transition-duration-l);
    }
  }

  /*doing this due to race condition of the toast not being dismissible while showing*/
  &:not(&--planner-collapsed) {
    .toast {
      opacity: 0;
    }
  }

  &--planner-collapsed {
    #{$base}__planner-toggle {
      top: 0;
      @include mixins.svg-fill($planner-icon-inactive-color);

      &:before {
        opacity: 0;
        background-color: $planner-icon-active-color;
        top: -6px;
      }

      &:hover {
        @include mixins.svg-fill($planner-icon-inactive-color-hover);

        &:before {
          background-color: $planner-icon-active-color-hover;
        }
      }
    }

    &#{$base}--planner-active {
      #{$base}__planner-toggle {
        @include mixins.svg-fill($planner-icon-active-color);

        &:before {
          opacity: 1;
          @include mixins.size(8px);
          background-color: $planner-icon-active-color;
        }

        &:hover {
          @include mixins.svg-fill($planner-icon-active-color-hover);
        }
      }
    }
  }

  &--planner {
    background-color: accent.$accent_08;

    .simplebar-scrollbar {
      &:before {
        background-color: accent.$accent_03 !important;
      }
    }
  }

  &--poi {
    overflow: hidden;
  }
}
