@use "sass/ui-library/utilities/mixins";
@use "sass/ui-library/utilities/variables/color/status";

.confirmation-dialog {
  $base: &;
  display: block;

  &__yes-button {
    color: status.$destructive_04;
    @include mixins.button-color-interaction(status.$destructive_04, status.$destructive_04)
  }
}
