@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/transitions";
@use "sass/utilities/variables/dimentions";

$translateY: -10px;
$translateX: 40px;
$close-height: 0;
$close-width: calc(#{dimentions.$base-width} * -1) !important;

.slide-in-down-out-up {
  $transition-main: height transitions.$transition-duration-m
    transitions.$transition-timing-function-panels;
  $transition-content: opacity transitions.$transition-duration-m
      transitions.$transition-timing-function-panels,
    transform transitions.$transition-duration-s
      transitions.$transition-timing-function-panels;

  &-enter {
    height: $close-height;
    overflow: hidden;

    & > .animation-wrapper__content {
      opacity: 0;
      -webkit-transform: translateY($translateY);
              transform: translateY($translateY);
    }
  }

  &-enter-active {
    /*height is being set through code*/
    -webkit-transition: $transition-main;
    transition: $transition-main;

    & > .animation-wrapper__content {
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-transition: $transition-content;
      transition: $transition-content;
    }
  }

  &-exit {
    overflow: hidden;

    & > .animation-wrapper__content {
      /*height is being set through code*/
      opacity: 1;
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
  }

  &-exit-active {
    height: $close-height !important;
    -webkit-transition: $transition-main;
    transition: $transition-main;

    & > .animation-wrapper__content {
      opacity: 0;
      -webkit-transform: translateY($translateY);
              transform: translateY($translateY);
      -webkit-transition: $transition-content;
      transition: $transition-content;
    }
  }
}

.move-in-left-out-right,
.slide-in-left-out-right {
  $transition-main: width transitions.$transition-duration-m
    transitions.$transition-timing-function-panels;
  $transition-content: opacity transitions.$transition-duration-m
      transitions.$transition-timing-function-panels,
    transform transitions.$transition-duration-s
      transitions.$transition-timing-function-panels;

  &-enter {
    overflow: hidden;
    width: $close-width;

    & > .animation-wrapper__content {
      opacity: 0;
      -webkit-transform: translateX($translateX);
              transform: translateX($translateX);
    }
  }

  &-enter-active {
    width: dimentions.$base-width !important;
    -webkit-transition: $transition-main;
    transition: $transition-main;

    & > .animation-wrapper__content {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
      -webkit-transition: $transition-content;
      transition: $transition-content;
    }
  }

  &-exit {
    overflow: hidden;
    width: dimentions.$base-width;

    & > .animation-wrapper__content {
      opacity: 1;
      -webkit-transform: translateX(0);
              transform: translateX(0);
    }
  }

  &-exit-active {
    width: $close-width;
    -webkit-transition: $transition-main;
    transition: $transition-main;

    & > .animation-wrapper__content {
      opacity: 0;
      -webkit-transform: translateX($translateX);
              transform: translateX($translateX);
      -webkit-transition: $transition-content;
      transition: $transition-content;
    }
  }
}

.fade-in-out {
  $transition: opacity transitions.$transition-duration-xl;

  &-enter {
    opacity: 0;
  }

  &-enter-active {
    opacity: 1;
    -webkit-transition: $transition ease-in-out;
    transition: $transition ease-in-out;
  }

  &-exit {
    opacity: 1;
  }

  &-exit-active {
    opacity: 0;
    -webkit-transition: $transition ease-in;
    transition: $transition ease-in;
  }
}
