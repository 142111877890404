@use "sass/ui-library/utilities/mixins";
@use "sass/ui-library/utilities/variables/effects";
@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/transitions";
@use "sass/utilities/variables/dimentions";

.login-component {
  $right: spacing.$spacing-s;
  position: fixed;
  right: $right;
  @include mixins.transition(transform, transitions.$transition-duration-xl);
  z-index: 10;
  top: spacing.$spacing-s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: end;
      -ms-flex-align: end;
          align-items: flex-end;

  &__login-button {
    @media (max-width: dimentions.$max-width-mobile) {
      .text, .text-with-icon__text {
        display: none;
      }
      padding: spacing.$spacing-xs;
    }
  }

  &__login-button:focus {
    color: neutral.$white_100;
  }

  &__logged-in-icon {
    @include mixins.size(40px);
    background-color: neutral.$white_100;
    border-radius: 20px;
    padding-left: spacing.$spacing-xs;
    padding-top: spacing.$spacing-xs;
    -webkit-box-shadow: effects.$drop-shadow-down-s-dark;
            box-shadow: effects.$drop-shadow-down-s-dark;
  }

  &__logged-in-icon:hover {
    background-color: accent.$accent_06;
    @include mixins.path-fill(neutral.$white_100);
  }

  &--active {
    background-color: accent.$accent_05;
    @include mixins.path-fill(neutral.$white_100);
  }
  &--active:hover {
    background-color: accent.$accent_06;
    @include mixins.path-fill(neutral.$white_100);
  }
  &--map-layers-panel-open {
    -webkit-transform: translateX(-(dimentions.$map-options-width));
            transform: translateX(-(dimentions.$map-options-width));
  }
}

.logged-in-card {
  background-color: neutral.$white_100;
  border-radius: spacing.$spacing-s;
  min-width: 254px;
  padding: spacing.$spacing-m;
  margin-top: spacing.$spacing-s;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;

  &__account-link {
    margin-top: spacing.$spacing-xs;
  }

  &__actions {
    margin-top: spacing.$spacing-l;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
}
