@use "sass/ui-library/utilities/variables/color/accent";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/transitions";
@use "sass/ui-library/utilities/mixins";
@use "sass/utilities/variables/dimentions";

.map-options-panel {
  $base: &;

  position: absolute;
  top: 0;
  right: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0;
  width: dimentions.$map-options-width;
  height: 100vh;

  &__map-styles-panel {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: spacing.$spacing-m;
    grid-row-gap: spacing.$spacing-s;
    padding: spacing.$spacing-xxs spacing.$spacing-m spacing.$spacing-m;
  }

  &__traffic-option, &__road-trips-option, &__scenic-segments-option {
    .lists-item__text {
      z-index: 1;
    }
  }

  @-webkit-keyframes focus-fade {
    $fade-gradient: linear-gradient(90deg, accent.$accent_02 95%, transparent);
    0% {
      background: accent.$accent_03;
      background: $fade-gradient;
      right: 100%;
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    80% {
      right: 0;
    }
    100% {
      background: accent.$accent_03;
      background: $fade-gradient;
      right: 0;
      opacity: 0;
    }
  }

  @keyframes focus-fade {
    $fade-gradient: linear-gradient(90deg, accent.$accent_02 95%, transparent);
    0% {
      background: accent.$accent_03;
      background: $fade-gradient;
      right: 100%;
      opacity: 1;
    }
    60% {
      opacity: 1;
    }
    80% {
      right: 0;
    }
    100% {
      background: accent.$accent_03;
      background: $fade-gradient;
      right: 0;
      opacity: 0;
    }
  }

  &__traffic-option--focus, &__road-trips-option--focus, &__scenic-segments-option--focus {
    position: relative;
    &:before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  $transition: transform transitions.$transition-duration-xl transitions.$transition-timing-function;
  $open: translateX(0);
  $closed: translateX(dimentions.$map-options-width);

  &.enter {
    -webkit-transform: $closed;
            transform: $closed;
  }

  &.enter-active {
    -webkit-transform: $open !important;
            transform: $open !important;
    -webkit-transition: $transition;
    transition: $transition;
  }

  &.enter-done {
    #{$base}__traffic-option--focus, #{$base}__road-trips-option--focus {
      &:before {
        -webkit-animation: focus-fade 500ms ease-out 500ms;
                animation: focus-fade 500ms ease-out 500ms;
      }
    }
  }

  &.exit {
    -webkit-transform: $open;
            transform: $open;
  }

  &.exit-active {
    -webkit-transform: $closed !important;
            transform: $closed !important;
    -webkit-transition: $transition;
    transition: $transition;
  }

  &__scroll {
    @include dimentions.heightCalc(max-height, "24px");
  }

  &__section {
    margin-bottom: spacing.$spacing-xs;
  }
}
