@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/mixins";

.send-destination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;

  &__tooltip-trigger {
    @include mixins.svg-fill(neutral.$neutral_04);
    margin-left: spacing.$spacing-xxs;
  }
}
