@use "sass/ui-library/utilities/variables/spacing";
@use "sass/ui-library/utilities/variables/color/neutral";
@use "sass/ui-library/utilities/mixins";

.preview-panel-actions {
  margin-top: spacing.$spacing-xxs;
  margin-bottom: spacing.$spacing-m;

  &__actions {
    display: grid;
    grid-template-columns: auto auto;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: start;
    -webkit-column-gap: spacing.$spacing-l;
       -moz-column-gap: spacing.$spacing-l;
            column-gap: spacing.$spacing-l;
    margin-top: spacing.$spacing-m;
    .is-container.disabled {
      cursor: not-allowed;
    }
  }

  &__share-dropdown {
    &::after {
      margin-left: spacing.$spacing-xs !important;
    }
    .text-with-icon {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
    }
  }
  &__share {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    .dropdown-menu {
      min-width: 0;
      a {
        cursor: pointer;
      }
    }
    .dropdown-item__icon {
      width: 24px;
    }
  }

  .btn-group {
    position: relative;
    .btn:not(:last-child):not(.dropdown-toggle) {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      padding-right: spacing.$spacing-xs;
    }

    &>:not(.btn-check)+.btn {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      padding-left: spacing.$spacing-xs;
      -webkit-box-shadow: none;
              box-shadow: none;
    }

    .dropdown-menu.show {
      -webkit-transform: none !important;
              transform: none !important;
      inset: 44px auto auto 0 !important;
      background-color: neutral.$neutral_01;
      padding: 0;
      border-radius: 8px;

      .dropdown-item {
        padding: 0.5rem 1rem;
        border-radius: 8px;
        &:hover, &:focus {
          background-color: neutral.$neutral_02;
        }
      }

    }
  }

  &__suggest-edit {
    margin-bottom: spacing.$spacing-xs;
    cursor: pointer;
  }

  &__share .dropdown-item {
    @include mixins.typography-properties("body");
  }
}
